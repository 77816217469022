import React, { useState, useEffect, useContext } from 'react'
import clsx from 'clsx';
import { appContext } from '../contexts/AppContext';
import { authContext } from '../contexts/AuthContext';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    
  },

}));

const AppTemplateKey = ({ children }) => {
  const classes = useStyles();

  return(
    <div className={classes.root}>
      {children}
    </div>
  )

};

export default AppTemplateKey;
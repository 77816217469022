import React, { useEffect, useContext, useState } from 'react'
import { appContext } from '../contexts/AppContext';
import { makeStyles } from '@material-ui/core/styles';
import Clock from 'react-live-clock';

import ShopWeDoAPI from '../contexts/ShopWeDoAPI'
import { Box, Card, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    overflow: 'hidden',
    fontFamily: '"Source Sans Pro", Arial, Tahoma, sans-serif',
    display: 'grid',
    gridTemplateColumns: 'repeat(16, 1fr)',
    gridTemplateRows: 'repeat(16, 1fr)',
    backgroundColor: '#2b363a',
    gridGap: '10px',
    width: '100vw',
    height: '100vh',
    padding: '10px'
  },
  clock: {
    gridColumn: '1 / span 16',
    gridRow: '1 / span 4',
    backgroundColor: '#2b363a',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    overflow: 'hidden'
  },
  clockLabel: {
    fontWeight: 500,
    fontSize: '16vh',
    lineHeight: '1em'
  },
  warehouseLabel: {
    fontSize: '5vh'
  },
  percentage: {
    gridColumn: '1 / span 10',
    gridRow: '5 / span 12',
    backgroundColor: '#ffd204',
    color: '#343a40',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  percentageLabel: {
    fontWeight: 500,
    fontSize: '36vh',
    lineHeight: '1em'
  },
  open: {
    gridColumn: '11 / span 6',
    gridRow: '5 / span 4',
    backgroundColor: '#395fdd',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingTop: '20px'
  },
  openLabel: {
    fontWeight: 500,
    fontSize: '16vh',
    lineHeight: '1em'
  },
  openLabelTitle: {
    fontSize: '3vh',
    lineHeight: '2em',
    textTransform: 'uppercase',
  },
  picked: {
    gridColumn: '11 / span 6',
    gridRow: '9 / span 4',
    backgroundColor: '#ffd204',
    color: '#343a40',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingTop: '20px'
  },
  pickedLabel: {
    fontWeight: 500,
    fontSize: '16vh',
    lineHeight: '1em'
  },
  pickedLabelTitle: {
    fontSize: '3vh',
    lineHeight: '2em',
    textTransform: 'uppercase',
  },
  shipped: {
    gridColumn: '11 / span 6',
    gridRow: '13 / span 4',
    backgroundColor: '#0ec545',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingTop: '20px'
  },
  shippedLabel: {
    fontWeight: 500,
    fontSize: '16vh',
    lineHeight: '1em'
  },
  shippedLabelTitle: {
    fontSize: '3vh',
    lineHeight: '2em',
    textTransform: 'uppercase'
  },
}));

function WarehouseMonitor({query}) {
  const classes = useStyles();
  const { changeTitle } = useContext(appContext);

  const [warehouse, setWarehouse] = useState('ShopWeDo')
  const [open, setOpen] = useState(0)
  const [picked, setPicked] = useState(0)
  const [shipped, setShipped] = useState(0)
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    changeTitle('Warehouse Outbound Monitor')
    ShopWeDoAPI.get('data/monitor/light?' + query.toString()).then(dataMonitorResponse => {
      let dataMonitor = dataMonitorResponse.data
      setWarehouse(dataMonitor.warehouse.name)
      setOpen(dataMonitor.open)
      setPicked(dataMonitor.collected)
      setShipped(dataMonitor.shipped)
      setPercentage(dataMonitor.percentage)
    })
    const intervalId = setInterval(() => {
      ShopWeDoAPI.get('data/monitor/light?' + query.toString()).then(dataMonitorResponse => {
        let dataMonitor = dataMonitorResponse.data
        setWarehouse(dataMonitor.warehouse.name)
        setOpen(dataMonitor.open)
        setPicked(dataMonitor.collected)
        setShipped(dataMonitor.shipped)
        setPercentage(dataMonitor.percentage)
      })
    }, 60000)
    return () => clearInterval(intervalId);
  }, [])

  return(
    <div className={classes.root}>
      <Box className={classes.clock}>
        <Typography className={classes.warehouseLabel} variant="h4" component="h2">{warehouse}</Typography>
        <Typography className={classes.clockLabel} variant="h1"><Clock format={'HH:mm:ss'} ticking={true} timezone={'Europe/Brussels'} /></Typography>
      </Box>
      <Box className={classes.percentage}>
        <Typography className={classes.percentageLabel} variant="h1">{percentage}%</Typography>
      </Box>
      <Box className={classes.open}>
        <Typography className={classes.openLabel} variant="h5" component="h2">{open}</Typography>
        <Typography className={classes.openLabelTitle}>Open</Typography>
      </Box>
      <Box className={classes.picked}>
        <Typography className={classes.pickedLabel} variant="h5" component="h2">{picked}</Typography>
        <Typography className={classes.pickedLabelTitle}>Picked</Typography>
      </Box>
      <Box className={classes.shipped}>
        <Typography className={classes.shippedLabel} variant="h5" component="h2">{shipped}</Typography>
        <Typography className={classes.shippedLabelTitle}>Shipped</Typography>
      </Box>
    </div>
  )
}

export default WarehouseMonitor;
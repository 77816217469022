import React from "react";
import { Route, Redirect, useLocation } from 'react-router-dom';

import AppTemplateKey from './AppTemplateKey';

function useQuery() {
return new URLSearchParams(useLocation().search);
}

const KeyRoute = ({ component: Component, ...rest }) => {
    
    let query = useQuery();
    
    return (
        <Route
        {...rest}
        render={(routeProps) => (
            query.get('key') === "5t4cDx68GX95Ag3O7npP8MHwQ3SX0moxjI175e4fuNBF63tjn2Q4zUO5K9Im90USa9QsSYmQ3KP5OYtP5LqqRQzbtVis7FH2" ? <AppTemplateKey {...routeProps}><Component query={query} {...routeProps} /></AppTemplateKey> : <Redirect to="/login" />
        )}
        />
    )

}

export default KeyRoute

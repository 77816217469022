import React, { useEffect, useContext, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import { appContext } from '../contexts/AppContext';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import StepContent from '@material-ui/core/StepContent';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import RoomIcon from '@material-ui/icons/Room';
import HelpIcon from '@material-ui/icons/Help';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


import Scanner from "../components/Scanner";

import ShopWeDoAPI from '../contexts/ShopWeDoAPI';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    top: '64px',
    right: 0,
    bottom: 0,
    left: 0,
    padding: 0,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      top: '56px',
    }
  },
  stepperContent: {
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    overflowY: 'scroll',
    overflowX: 'hidden',
    textAlign: 'center',
    flex: 'auto',
    transition: 'all .350s ease-in-out'
  },
  stepperContentWithError: {
    backgroundColor: theme.palette.error.main,
  },
  stepperContentIsCorrect: {
    backgroundColor: theme.palette.success.main,
  },
  stepperLabels: {
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.background.main
  },
  previousStepLabel: {
    backgroundColor: theme.palette.secondary.main,
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    margin: 0,
    padding: '10px',
    flex: 'auto'
  },
  nextStepLabel: {
    backgroundColor: theme.palette.secondary.main,
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    margin: 0,
    padding: '10px',
    flex: 'auto'
  },
  stepLabelActive: {
    borderTop: 'none',
    backgroundColor: theme.palette.background.main
  },
  stepLabelComplete: {
    backgroundColor: theme.palette.success.main
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  stockButton: {
    marginBottom: theme.spacing(2)
  },
  buttonLine: {
    display: 'block'
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  labelIconRoot: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  labelIconActive: {
    color: 'rgba(0, 0, 0, 1)',
  },
  labelIconCompleted: {
    color: 'rgba(0, 0, 0, 0.75)'
  },
  active: {
    color: theme.palette.secondary.main,
    '& $line': {
      borderColor: theme.palette.secondary.main,
    }
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.success.main,
    zIndex: 1,
    fontSize: 18,
  },
  sizeTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  sizeButtonPrefill: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '&:active': {
      backgroundColor: theme.palette.success.main
    }
  },
  stepTitle: {
    marginBottom: theme.spacing(2)
  },
  stepButton: {
    textAlign: 'left'
  }
}));

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.secondary.main,
    }
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.success.main,
    },
  },
  line: {
    borderColor: theme.palette.secondary.main,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

function QontoStepIcon(props) {
  const classes = useStyles();
  const { active, completed } = props;

  const icons = {
    1: <HelpIcon />,
    2: <TouchAppIcon />,
    3: <SettingsEthernetIcon />,
    4: <RoomIcon />,
  };

  return (
    <div
      className={clsx(classes.labelIconRoot, {
        [classes.labelIconActive]: active,
        [classes.labelIconCompleted]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const renderStep = (step, classes, state, actions) => {
  switch (step) {
    case 0:
      return <ToBeReplenished classes={classes} state={state} actions={actions}/>;
    case 1:
      return <VerifyStock classes={classes} state={state} actions={actions}/>;
    case 2:
      return <LocationSize classes={classes} state={state} actions={actions}/>;
    case 3:
      return <NewLocation classes={classes} state={state} actions={actions}/>;
    default:
      return 'Unknown step';
  }
}

const ToBeReplenished = ({ classes, state, actions }) => {
  const toBeReplenishedScanner = useRef()
  const [stocks, setStocks] = useState()

  useEffect(() => {
    toBeReplenishedScanner.current.focus()
  }, [])

  useEffect(() => {
    if (stocks) actions.onSearchDone(stocks)
  }, [stocks])

  const handleScanEnd = (value) => {
    ShopWeDoAPI.get('stocks/search?q=' + value).then(stocksResponse => {
      let stocks = stocksResponse.data
      setStocks(stocks)
    }).catch(err => {
      actions.onSearchFailed()
    })
  }

  return (
    <div>
      <HelpIcon />
      <Typography className={classes.stepTitle} variant="h6">Wat wil je verplaatsen?</Typography>
      <Scanner ref={toBeReplenishedScanner} onScanEnd={handleScanEnd} placeholder='Wat wil je verplaatsen?' />
    </div>
  )
}

const VerifyStock = ({ classes, state, actions }) => {

  return (
    <div>
      <TouchAppIcon />
      <Typography className={classes.stepTitle} variant="h6">Product selecteren</Typography>
      {state.searchResults.length > 0 &&
        <List dense={true}>
          {state.searchResults.map(stock => (
            <ListItem key={stock.id} onClick={() => actions.onSelectedStock(stock)} button={true} divider={true}>
              <ListItemText primary={stock.level + ' x ' + stock.name + ' - ' + stock.barcode} secondary={'LOCATIE: ' + stock.location + ' THT: ' + stock.thtDate + ' LOT: ' + stock.lot} />
            </ListItem>
          ))}
        </List>
      }
    </div>
  )
}

const LocationSize = ({ classes, state, actions }) => {

  return (
    <div>
      <SettingsEthernetIcon />
      <Typography className={classes.stepTitle} variant="h6">Locatiegrootte aanduiden</Typography>
      <Typography className={classes.sizeTitle}>Metalen rekken</Typography>
      <ButtonGroup size="small" variant="contained" color="default" aria-label="outlined primary button group">
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'XXS')})} onClick={() => actions.onSelectedSize('XXS')}>XXS</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'XS')})} onClick={() => actions.onSelectedSize('XS')}>XS</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'S')})} onClick={() => actions.onSelectedSize('S')}>S</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'M')})} onClick={() => actions.onSelectedSize('M')}>M</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'L')})} onClick={() => actions.onSelectedSize('L')}>L</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'XL')})} onClick={() => actions.onSelectedSize('XL')}>XL</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'XXL')})} onClick={() => actions.onSelectedSize('XXL')}>XXL</Button>
      </ButtonGroup><br/>
      <Typography className={classes.sizeTitle}>Houten legplanken</Typography>
      <ButtonGroup size="small" variant="contained" color="default" aria-label="outlined primary button group">
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'LONGSPAN_S')})} onClick={() => actions.onSelectedSize('LONGSPAN_S')}>Plank S</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'LONGSPAN_M')})} onClick={() => actions.onSelectedSize('LONGSPAN_M')}>Plank M</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'LONGSPAN_L')})} onClick={() => actions.onSelectedSize('LONGSPAN_L')}>Plank L</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'LONGSPAN_XL')})} onClick={() => actions.onSelectedSize('LONGSPAN_XL')}>Plank XL</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'LONGSPAN_XXL')})} onClick={() => actions.onSelectedSize('LONGSPAN_XXL')}>Plank XXL</Button>
      </ButtonGroup><br/>
      <Typography className={classes.sizeTitle}>Palletplaatsen</Typography>
      <ButtonGroup size="small" variant="contained" color="default" aria-label="outlined primary button group">
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'PALLET_FULL')})} onClick={() => actions.onSelectedSize('PALLET_FULL')}>Volle</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'PALLET_HALF')})} onClick={() => actions.onSelectedSize('PALLET_HALF')}>Halve</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'PALLET_HIGH')})} onClick={() => actions.onSelectedSize('PALLET_HIGH')}>Hoge</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'PALLET_OVERSTOCK')})} onClick={() => actions.onSelectedSize('PALLET_FULL_OVERSTOCK')}>Overstock</Button>
        <Button className={clsx({[classes.sizeButtonPrefill]: (state.selectedStock.locationSize == 'PALLET_UK')})} onClick={() => actions.onSelectedSize('UK_PALLET_FULL')}>UK</Button>
      </ButtonGroup>
    </div>
  )
}

const NewLocation = ({ state, actions }) => {
  const newLocationScanner = useRef()

  useEffect(() => {
    newLocationScanner.current.focus()
  }, [])

  const handleScanEnd = (value) => {
    ShopWeDoAPI.put('stocks/' + state.selectedStock.id + '/location', {
      newLocation: value,
      shopId: state.selectedStock.shopId,
      oldLocation: state.selectedStock.location,
      newLocationSize: state.selectedSize
    }).then(() => {
      actions.onReplenishDone(value)
    }).catch(err => {
      actions.onReplenishFailed()
    })
  }

  return (
    <div>
      <Scanner ref={newLocationScanner} onScanEnd={handleScanEnd} placeholder='Scan nieuwe locatie' />
    </div>
  )
}

function Replenisher() {
  const classes = useStyles()
  const { changeTitle } = useContext(appContext)

  const [activeStep, setActiveStep] = useState(0)

  const initialSteps = ['Wat wil je verplaatsen?', 'Product selecteren', 'Locatiegrootte aanduiden', 'Nieuwe locatie geven']

  const [isError, setIsError] = useState(false)
  const [isCorrect, setIsCorrect] = useState(false)

  const [steps, setSteps ] = useState(initialSteps)

  const [ searchResults, setSearchResults ] = useState([])
  const [ selectedStock, setSelectedStock ] = useState(null)
  const [ selectedSize, setSelectedSize ] = useState(null)

  const [snackbarOpen, snackbarSetOpen] = useState(false);
  const [snackbarMessage, snackbarSetMessage] = useState('De locatie is met succes gewijzigd!');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    snackbarSetMessage('De locatie is met succes gewijzigd!')
    snackbarSetOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    const newSteps = [...steps];
    if (step == 2) {
      newSteps[2] = 'Locatiegrootte aanduiden'
    }
    if (step == 1) {
      newSteps[1] = 'Product selecteren'
      newSteps[2] = 'Locatiegrootte aanduiden'
      setSelectedStock(null)
    }
    if (step == 0) {
      newSteps[0] = 'Wat wil je verplaatsen?'
      newSteps[1] = 'Product selecteren'
      newSteps[2] = 'Locatiegrootte aanduiden'
    }
    setSteps(newSteps)
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const state = { searchResults, selectedStock, selectedSize }

  useEffect(() => {
    if (selectedStock) {
      const newSteps = [...steps];
      newSteps[0] = selectedStock.location
      newSteps[1] = selectedStock.level + ' x ' + selectedStock.barcode + ' - ' + selectedStock.name
      setSteps(newSteps)
    }
  }, [selectedStock])

  useEffect(() => {
    if (selectedSize) {
      const newSteps = [...steps];
      newSteps[2] = selectedSize
      setSteps(newSteps)
    }
  }, [selectedSize])

  const actions = {
    onSearchDone: (results) => {
      setIsError(false)
      setSearchResults(results)
      setIsCorrect(true)
      setTimeout(() => {
        setIsCorrect(false)
        if (results.length == 1) {
          setSelectedStock(results[0])
          setActiveStep(2)
        } else {
          setActiveStep(1)
        }
      }, 350)
      
    },
    onSearchFailed: () => {
      setIsError(true)
    },
    onSelectedStock: (selected) => {
      setSelectedStock(selected)
      setActiveStep(2)
    },
    onSelectedSize: (size) => {
      setSelectedSize(size)
      setActiveStep(3)
    },
    onReplenishDone: (value) => {
      setIsError(false)
      setSearchResults([])
      setSelectedStock(null)
      setSelectedSize(null)
      setIsCorrect(true)
      setTimeout(() => {
        setIsCorrect(false)
        setActiveStep(0)
        const newSteps = initialSteps;
        setSteps(newSteps)
        snackbarSetMessage('De locatie is gewijzigd naar '+value)
        snackbarSetOpen(true);
      }, 350)
    },
    onReplenishFailed: () => {
      setIsError(true)
    }
  }

  useEffect(() => {
    changeTitle('Verplaatsen')
  }, [])

  return(
    <Stepper className={clsx(classes.root, {
        [classes.rootStart]: activeStep == 0, 
        [classes.rootEnd]: activeStep == steps.length
      })} alternativeLabel={false} activeStep={activeStep} orientation="vertical" connector={null}>

      {steps.map((label, index) => {
        if (index < activeStep) {
          return (
            <Step className={classes.previousStep}>
              <StepButton onClick={handleStep(index)} className={classes.stepButton}><StepLabel className={clsx([classes.previousStepLabel, classes.stepLabelComplete])} StepIconComponent={QontoStepIcon}>{label}</StepLabel></StepButton>
            </Step>
          )
        }
        if (index === activeStep) {
          return (
            <Box className={clsx(classes.stepperContent, {[classes.stepperContentWithError]: isError, [classes.stepperContentIsCorrect]: isCorrect})}>{renderStep(activeStep, classes, state, actions)}</Box>
          )
        }
        if (index > activeStep) {
          return (
            <Step className={classes.nextStep}>
              <StepLabel className={classes.nextStepLabel} StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          )
        }
      })}
      <Snackbar open={snackbarOpen} autoHideDuration={7000} onClose={handleSnackbarClose}>
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
    </Stepper>
  )
}

export default Replenisher;
import React, { createContext, useState, useEffect } from 'react';
import { decodeToken } from "react-jwt";

export const authContext = createContext({});

const AuthProvider = ({ children }) => {

  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'))
  const [user, setUser] = useState({
    id: null,
    email: null,
    firstname: null,
    lastname: null,
    level: null
  })

  const getLevel = (groups) => {
    let level = 0
    const starter = groups.filter(i => i.name === "warehouse_starter")
    if (starter.length === 1) level = 1
    const full = groups.filter(i => i.name === "warehouse_full")
    if (full.length === 1) level = 2
    const manager = groups.filter(i => i.name === "warehouse_manager")
    if (manager.length === 1) level = 3
    return level
  }

  const restoreUser = (accessToken) => {
    if (accessToken) {
      const restoredDecodedToken = decodeToken(accessToken)
      setUser({
        id: restoredDecodedToken.id,
        email: restoredDecodedToken.email,
        firstname: restoredDecodedToken.firstname,
        lastname: restoredDecodedToken.lastname,
        level: getLevel(restoredDecodedToken.groups)
      })
    }
  }

  const hasAccess = (accessToken, level) => {
    if (accessToken) {
      const restoredDecodedToken = decodeToken(accessToken)
      if (getLevel(restoredDecodedToken.groups) >= level) {
        return true
      }
    }
    return false
  }

  const login = (accessToken) => {
    localStorage.setItem('accessToken', accessToken)
    const myDecodedToken = decodeToken(accessToken)
    setUser({
      id: myDecodedToken.id,
      email: myDecodedToken.email,
      firstname: myDecodedToken.firstname,
      lastname: myDecodedToken.lastname,
      level: getLevel(myDecodedToken.groups)
    })
    setAccessToken(accessToken)
  }

  const logout = () => {
    localStorage.removeItem('accessToken')
    setUser(null)
    setAccessToken(null)
  }
  
  return (
    <authContext.Provider value={{ accessToken, hasAccess, login, logout, user, restoreUser }}>
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;
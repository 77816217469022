import React, { useEffect, useState, useContext } from 'react';
import { appContext } from '../contexts/AppContext';
import { authContext } from '../contexts/AuthContext';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import ShopWeDoAPI from '../contexts/ShopWeDoAPI'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 400,
    margin: `${theme.spacing(0)} auto`
  },
  loginBtn: {
    marginTop: theme.spacing(2),
    flexGrow: 1
  },
  header: {
    textAlign: 'center',
    background: theme.palette.primary.main,
    color: '#fff'
  },
  headerImage: {
    margin: 0
  },
  card: {
    background: theme.palette.background.light,
    marginTop: theme.spacing(10)
  },
}));

const Login = ({history}) => {
  const classes = useStyles();

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [helperText, setHelperText] = useState('')
  const [isError, setIsError] = useState(false)

  const { login } = useContext(authContext);
  const { changeTitle } = useContext(appContext);

  useEffect(() => {
    changeTitle('Login')
  }, [])

  useEffect(() => {
    if (username.trim() && password.trim()) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }, [username, password]);

  const handleLogin = () => {
    ShopWeDoAPI.post('auth/login', {
      email: username,
      password: password
    }).then(loginResponse => {
      if (loginResponse.status == 200 && loginResponse.data) {
        login(loginResponse.data.accessToken)
        history.replace('/')
      }
    }).catch(err => {
      setHelperText('Incorrect username or password')
      setIsError(true)
    })
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      isButtonDisabled || handleLogin();
    }
  }

  const handleUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  return(
    <form className={classes.container} noValidate autoComplete="off">
          <Card className={classes.card}>
            <CardHeader className={classes.header} title={<img className={classes.headerImage} src="logo.png"/>}></CardHeader>
            <CardContent>
              <div>
                <TextField
                  error={isError}
                  fullWidth
                  id="username"
                  type="email"
                  label="E-mailadres"
                  placeholder="E-mailadres"
                  margin="normal"
                  onChange={handleUsernameChange}
                  onKeyPress={handleKeyPress}
                />
                <TextField
                  error={isError}
                  fullWidth
                  id="password"
                  type="password"
                  label="Wachtwoord"
                  placeholder="Wachtwoord"
                  margin="normal"
                  helperText={helperText}
                  onChange={handlePasswordChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                className={classes.loginBtn}
                onClick={handleLogin}
                disabled={isButtonDisabled}>
                Aanmelden
              </Button>
            </CardActions>
          </Card>
        </form>
  )

}

export default Login;
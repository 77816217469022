import React, { useState, useEffect, useContext } from 'react'
import { appContext } from '../contexts/AppContext';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ShopWeDoAPI from '../contexts/ShopWeDoAPI'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  tableRowWarning: {
    backgroundColor: theme.palette.secondary.light
  },
  tableRowDanger: {
    backgroundColor: theme.palette.error.light
  },
}));

function PutawayMonitoring() {
  const classes = useStyles();
  const { changeTitle } = useContext(appContext);

  const [putaways, setPutaways] = useState([])

  useEffect(() => {
    changeTitle('Ingeslagen voorraad')
    ShopWeDoAPI.get('inbounds/putaways').then(putawaysResponse => {
      let putaways = putawaysResponse.data
      putaways.map(putaway => {
        let diff = moment().diff(moment(putaway.updatedAt), 'days')
        putaway.diff = diff
      })
      setPutaways(putaways)
    })
  }, [])

  const PutawayRow = ({children, key, putaway}) => {
    if (putaway.diff == 1) {
      return <TableRow className={classes.tableRowWarning} key={key}>{children}</TableRow>
    } else if (putaway.diff > 1) {
      return <TableRow className={classes.tableRowDanger} key={key}>{children}</TableRow>
    }
    return <TableRow key={key}>{children}</TableRow>
  }

  return(
    <div className={classes.root}>
      {putaways && putaways.length > 0 &&
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Inbound</TableCell>
                <TableCell>Aantal</TableCell>
                <TableCell>Stock</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {putaways.map((putaway, index) => (
                <PutawayRow key={index} putaway={putaway}>
                  <TableCell>INL_{putaway.id}</TableCell>
                  <TableCell>{putaway.inboundId}</TableCell>
                  <TableCell>{putaway.putawayAmount}</TableCell>
                  <TableCell>{putaway.stock.shop.name}<br/>[SKU {putaway.stock.id}] {putaway.stock.name}</TableCell>
                </PutawayRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </div>
  )
}

export default PutawayMonitoring;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import AppProvider from './contexts/AppContext';
import AuthProvider from './contexts/AuthContext';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1c2b36"
    },
    secondary: {
      main: "#ffcb04"
    },
    success: {
      main: "#37b750"
    },
    error: {
      main: "#df2935"
    },
    background: {
      main: "#e1e3eb",
      light: "#e7e9f1"
    }
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}><AppProvider><AuthProvider>
    <App />
  </AuthProvider></AppProvider></ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

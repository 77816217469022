import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import CropFreeIcon from '@material-ui/icons/CropFree';

const useStyles = makeStyles((theme) => ({
  scanner: {
    position: 'relative',
    width: '100%',
    height: '50px'
  },
  scannerInputWrap: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    margin: '0',
    padding: '0',
    border: '0'
  },
  scannerInput: {
    width: '100%',
    height: '100%',
    margin: '0',
    padding: '0',
    border: '0',
    outline: 'none',
    backgroundColor: 'transparent',
    textAlign: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:focus': {
      border: '1px solid #3F67FF'
    }
  },
  keyboardInputWrap: {
    position: 'absolute',
    top: '0',
    right: '140px',
    bottom: '0',
    left: '0',
    margin: '0',
    padding: '0',
    border: '0'
  },
  keyboardInput: {
    width: '100%',
    height: '100%',
    margin: '0',
    padding: '0 0 0 10px',
    border: '0',
    outline: 'none',
    backgroundColor: 'transparent',
    textAlign: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:focus': {
      border: '1px solid #3F67FF'
    }
  },
  withValue: {
    textAlign: 'left'
  },
  keyboardTogglerWrap: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0'
  },
  keyboardToggler: {
    position: 'absolute',
    top: '7px',
    right: '10px',
    bottom: '7px',
    width: '36px',
    margin: '0',
    padding: '0',
    border: '0',
    color: '#3F67FF',
    backgroundColor: '#EAEFFF',
    borderRadius: '18px',
    fontSize: '20px',
    outline: 'none',
    boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
    '&:focus': {
      fontSize: '16px',
      boxShadow: 'none'
    }
  },
  keyboardSubmitWrap: {
    position: 'absolute',
    top: '7px',
    right: '56px',
    bottom: '7px',
    width: '140px'
  },
  keyboardSubmit: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    margin: '0',
    padding: '0 20px',
    border: '0',
    color: '#F0F1F6',
    backgroundColor: '#3F67FF',
    borderRadius: '18px',
    outline: 'none'
  }
}));

const Scanner = forwardRef((props, ref) => {
  const classes = useStyles()

  const scannerInput = useRef()
  const keyboardInput = useRef()
  const isFirstRun = useRef(true)

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (keyboard) keyboardInput.current.focus()
      if (!keyboard) scannerInput.current.focus()
    }
  }));

  const [value, setValue] = useState('')
  const [keyboard, setKeyboard] = useState(false)

  useEffect(() => {
    if (isFirstRun.current && !props.focus) {
      isFirstRun.current = false
      return
    }
    if (keyboard) keyboardInput.current.focus()
    if (!keyboard) scannerInput.current.focus()
  }, [keyboard, props.focus])

  const toggleKeyboard = () => setKeyboard(!keyboard)

  const onKeyUp = (ev) => {
    if (['Shift', 'Unidentified'].includes(ev.key)) return
    if (ev.key === 'Enter') {
      if (typeof props.onScanEnd === 'function') props.onScanEnd(value)
      setValue('')
    } else {
      let newValue = value + ev.key
      setValue(newValue)
    }
  }

  const onChange = (ev) => setValue(ev.target.value)

  const onSubmit = (ev) => {
    if (typeof props.onScanEnd === 'function') props.onScanEnd(value)
    setValue('')
  }

  return (
    <Paper style={{backgroundColor: "rgba(255, 255, 255, 0.75)"}} elevation={1} className={classes.scanner}>
      {!keyboard && <div className={classes.scannerInputWrap}><input ref={scannerInput} className={classes.scannerInput} type="text" onKeyPress={onKeyUp} readOnly value={value} placeholder={props.placeholder}/></div>}
      {keyboard && <div className={classes.keyboardInputWrap}><input ref={keyboardInput} className={clsx(classes.keyboardInput, {
        [classes.withValue]: value,
      })} type="text" onChange={onChange} value={value} placeholder={props.placeholder}/></div>}
      {keyboard && value && <div className={classes.keyboardSubmitWrap}><button className={classes.keyboardSubmit} type="button" onClick={onSubmit}>Stuur</button></div>}
      <div className={classes.keyboardTogglerWrap}><button className={classes.keyboardToggler} onClick={toggleKeyboard}>{keyboard ? <span><CropFreeIcon /></span> : <span><KeyboardIcon /></span>}</button></div>
    </Paper>
  );
})

Scanner.defaultProps = {
  placeholder: "Scan een barcode"
}

export default Scanner;

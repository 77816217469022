import React, { useState, useEffect, useContext } from 'react'
import { appContext } from '../contexts/AppContext';
import { authContext } from '../contexts/AuthContext';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/nl-be';
import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ShopWeDoAPI from '../contexts/ShopWeDoAPI'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  planningTitle: {
    textTransform: 'uppercase'
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
    maxWidth: '100%'
  },
  table: {
    minWidth: '100%',
  },
  taskCell: {
    width: '200px'
  },
  startCell: {
    width: '300px'
  },
  endCell: {
    width: '300px'
  },
  shiftToolbar: {
    display: 'flex',
  },
  shiftName: {
    flexGrow: 1,
    textTransform: 'uppercase'
  },
  shiftValue: {
    marginRight: theme.spacing(2)
  },
  cardRoot: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  contentRoot: {
    paddingLeft: 0,
    paddingRight: 0
  },
  taskTitle: {
    marginTop: theme.spacing(1)
  },
  taskEntry: {
    marginTop: theme.spacing(1),
    borderTop: '1px solid rgb(0 0 0 / 12%)'
  },
  statsWrap: {
    marginBottom: theme.spacing(2)
  },
  statsPicking: {
    padding: theme.spacing(2),
  },
  statsShipping: {
    padding: theme.spacing(2),
  }
}));

function Homepage() {
  const classes = useStyles();
  const { changeTitle, setNumberOfTasks } = useContext(appContext);

  const { user } = useContext(authContext);

  const [ shifts, setShifts ] = useState([])
  const [ tasks, setTasks ] = useState([])
  const [ pickingOrdersCount, setPickingOrdersCount ] = useState(0)
  const [ pickingOrderlinesCount, setPickingOrderlinesCount ] = useState(0)
  const [ shippingOrdersCount, setShippingOrdersCount ] = useState(0)
  const [ shippingOrderlinesCount, setShippingOrderlinesCount ] = useState(0)

  useEffect(() => {
    setNumberOfTasks(tasks.filter(i => !i.done).length)
  }, [tasks])

  useEffect(() => {
    changeTitle('Dashboard')
    if (user.id) {
      ShopWeDoAPI.get('users/'+ user.id +'/shifts/' + moment().format("YYYY/MM/DD")).then(usersShiftsResponse => {
        setShifts(usersShiftsResponse.data)
      })
      ShopWeDoAPI.get('users/'+ user.id +'/stats/' + moment().subtract({days: 1}).format("YYYY/MM/DD") + '/picking').then(pickingStatsResponse => {
        if (pickingStatsResponse.data.length > 0) {
          setPickingOrdersCount(pickingStatsResponse.data[0].ordersCount)
          setPickingOrderlinesCount(pickingStatsResponse.data[0].skusCount)
        }
      })
      ShopWeDoAPI.get('users/'+ user.id +'/stats/' + moment().subtract({days: 1}).format("YYYY/MM/DD") + '/shipping').then(shippingStatsResponse => {
        if (shippingStatsResponse.data.length > 0) {
          setShippingOrdersCount(shippingStatsResponse.data[0].ordersCount)
          setShippingOrderlinesCount(shippingStatsResponse.data[0].skusCount)
        }
      })
      if (user.level >= 3) {
        ShopWeDoAPI.get('tasks/feed/' + moment().format("YYYY/MM/DD")).then(usersTasksResponse => {
          setTasks(usersTasksResponse.data)
        })
      } else {
        ShopWeDoAPI.get('users/'+ user.id +'/tasks/' + moment().format("YYYY/MM/DD")).then(usersTasksResponse => {
          setTasks(usersTasksResponse.data)
        })
      }
    }
  }, [user.id])

  const toggleTask = (ev, index, task) => {
    ev.stopPropagation()
    const newValue = !task.done
    const newTasks = [...tasks];
    newTasks[index].done = newValue;
    setTasks(newTasks);
    ShopWeDoAPI.put('tasks/'+ task.id, {done: newValue})
  }

  return(
    <div className={classes.root}>
      {shifts.map((shift, index) => (
        <Paper className={classes.cardRoot}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}><Typography className={classes.shiftName} variant="body1" noWrap>{shift.user.firstname + ' ' + shift.user.lastname}</Typography></Grid>
            <Grid item xs={3}><Typography variant="h6" align="center" display="block" noWrap><Moment format="HH:mm">{shift.start}</Moment></Typography><Typography variant="caption" color="textSecondary" align="center" display="block" noWrap>BEGIN</Typography></Grid>
            <Grid item xs={3}><Typography variant="h6" align="center" display="block" noWrap><Moment format="HH:mm">{shift.end}</Moment></Typography><Typography variant="caption" color="textSecondary" align="center" display="block" noWrap>EINDE</Typography></Grid>
            <Grid item xs={3}><Typography variant="h6" align="center" display="block" noWrap>{shift.duration}</Typography><Typography variant="caption" align="center" color="textSecondary" display="block" noWrap>UREN</Typography></Grid>
            <Grid item xs={3}><Typography variant="h6" align="center" display="block" noWrap>{shift.break}</Typography><Typography variant="caption" align="center" color="textSecondary" display="block" noWrap>PAUZE</Typography></Grid>
          </Grid>
          {shift.assignments && shift.assignments.length > 0 &&
            <Grid container spacing={2} className={classes.taskTitle}><Grid item xs={12}><Typography variant="caption" align="left" display="block" noWrap>TAKEN</Typography></Grid></Grid>
          }
          {shift.assignments && shift.assignments.length > 0 &&
            shift.assignments.map((assignment, index) => (
              <Grid container spacing={2} className={classes.taskEntry}>
                <Grid item xs={4}>{assignment.assignment.description}</Grid>
                <Grid item xs={4}><Moment format="HH:mm">{assignment.start}</Moment></Grid>
                <Grid item xs={4}><Moment format="HH:mm">{assignment.end}</Moment></Grid>
              </Grid>
            ))
          }
        </Paper>
        ))}
        <Grid className={classes.statsWrap} container spacing={2} alignItems="center">
          <Grid item xs={6}><Paper className={classes.statsPicking} align="center"><Typography variant="h6" align="center" display="block" noWrap>{pickingOrdersCount} orders</Typography><Typography variant="subtitle1" align="center" display="block" noWrap>{pickingOrderlinesCount} lijnen</Typography><Typography variant="caption" color="textSecondary" align="center" display="block" noWrap>GISTEREN VERZAMELD</Typography></Paper></Grid>
          <Grid item xs={6}><Paper className={classes.statsShipping} align="center"><Typography variant="h6" align="center" display="block" noWrap>{shippingOrdersCount} orders</Typography><Typography variant="subtitle1" align="center" display="block" noWrap>{shippingOrderlinesCount} lijnen</Typography><Typography variant="caption" color="textSecondary" align="center" display="block" noWrap>GISTEREN VERZONDEN</Typography></Paper></Grid>
        </Grid>
      {tasks.length > 0 && (
        <Paper className={classes.tableContainer}>
          <Toolbar className={classes.shiftToolbar}>
            <Typography className={classes.shiftName} variant="h5" noWrap>TAKEN</Typography>
          </Toolbar>
          {tasks.map((task, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
              <FormControlLabel
                aria-label="Mark as done"
                onClick={(ev) => toggleTask(ev, index, task)}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox />}
                label={task.title}
                checked={task.done}
              />
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="textSecondary">{task.description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
      )}
    </div>
  )
}

export default Homepage;
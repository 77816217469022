import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateTimePicker from '../components/DateTimePicker'
import FormDialog from '../components/FormDialog'
import moment from 'moment';

import ShopWeDoAPI from '../contexts/ShopWeDoAPI'

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  withMargin: {
    margin: theme.spacing(2),
  }
}));

export default function AddShiftDialog({ onShiftPosted, date, onClose, open }) {
  const classes = useStyles()

  const [workers, setWorkers] = useState([])
  useEffect(() => {
    ShopWeDoAPI.get('workers').then(workersResponse => {
      setWorkers(workersResponse.data)
    })
  }, [])

  useEffect(() => {
    setStartDate(date)
    setEndDate(date)
  }, [date])

  const [worker, setWorker] = useState(null)
  const [startDate, setStartDate] = useState(moment(date))
  const [endDate, setEndDate] = useState(moment(date))
  const [breakTime, setBreakTime] = useState(30)

  const handleWorkerChange = (_, v) => setWorker(v)
  const handleStartDateChange = (d) => setStartDate(d)
  const handleEndDateChange = (d) => setEndDate(d)
  const handleBreakTimeChange = (t) => setBreakTime(t)

  const onSubmit = () => {
    ShopWeDoAPI.post('shifts', {
      userId: worker.id,
      start: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      break: breakTime
    }).then(_ => {
      if (typeof onShiftPosted === 'function') onShiftPosted()
    })
  }

  return (
    <div>
      <FormDialog onSubmit={onSubmit} onClose={onClose} open={open} title="Shift toevoegen">
      <Autocomplete 
              className={classes.withMargin}
              id="combo-box-demo" 
              options={workers} 
              getOptionLabel={(option) => option.fullname} 
              style={{ display: 'block' }} 
              onChange={handleWorkerChange}
              renderInput={(params) => <TextField {...params} label="Magazijnier" variant="outlined" />}
            />

            <DateTimePicker dateLabel='Start datum' timeLabel='Start uur' value={startDate} onDateChange={handleStartDateChange} />
            <DateTimePicker dateLabel='Einde datum' timeLabel='Einde uur' value={endDate} onDateChange={handleEndDateChange} />

            <TextField
              className={classes.withMargin}
              label="Pauze"
              id="outlined-start-adornment"
              defaultValue={breakTime}
              InputProps={{
                endAdornment: <InputAdornment position="start">min</InputAdornment>,
              }}
              onChange={handleBreakTimeChange}
              variant="outlined"
            />
      </FormDialog>
    </div>
  );
}
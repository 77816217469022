import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import DateFnsUtils from '@date-io/date-fns';
import nlBeLocale from "date-fns/locale/nl-be";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/nl-be';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  datePickerButton: {
    cursor: 'pointer'
  }
}));

const DateSwitcher = ({ urlDate, onDateChange, children }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(urlDate)

  useEffect(() => {
    if (typeof onDateChange === 'function') onDateChange(date)
  }, [date])

  const changeDate = (newDate) => setDate(newDate)

  return (
    <div className={classes.root}>
      <IconButton className={classes.button} variant="contained" color="primary" onClick={ () => changeDate(moment(date).subtract({ days: 1 }))}><KeyboardArrowLeftIcon /></IconButton>
        <div className={classes.datePickerButton} onClick={() => setIsOpen(true)}>{children}</div>
      <IconButton className={classes.button} variant="contained" color="primary" onClick={ () => changeDate(moment(date).add({ days: 1 }))}><KeyboardArrowRightIcon /></IconButton>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlBeLocale}><KeyboardDatePicker
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          variant="dialog"
          format="dd/MM/yyyy"
          value={date}
          onChange={changeDate}
          TextFieldComponent={() => null}
        /></MuiPickersUtilsProvider>
    </div>
  )
}

export default DateSwitcher

import React, { useState, useEffect, useContext } from 'react'
import clsx from 'clsx';
import { appContext } from '../contexts/AppContext';
import { authContext } from '../contexts/AuthContext';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  accountMenuPaper: {
    backgroundColor: theme.palette.background.light
  },
  accountMenuTitle: {
    marginLeft: theme.spacing(1),
    textTransform: 'none'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuButtonOpen: {
    marginRight: theme.spacing(2),
    color: 'white'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.main,
    border: 'none',
  },
  drawerHeader: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  appBarLogo: {
    height: '46px',
    paddingTop: '6px'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  }
}));

const AppTemplate = ({ children, history }) => {

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const { numbersOfTasks, title, changeTitle } = useContext(appContext);

  const { logout, user } = useContext(authContext);

  useEffect(() => {
    changeTitle('Dashboard')
  }, [])

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const accountMenuOpen = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (redirect) => {
    setOpen(false);
    if (redirect) {
      history.replace(redirect)
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return(
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" noWrap className={classes.title}><img className={classes.appBarLogo} src='/logo.png'/></Typography>
          <div>
              <IconButton aria-label={`Je hebt ${numbersOfTasks} taken`} color="inherit">
                <Badge badgeContent={numbersOfTasks} color="secondary">
                  <AssignmentTurnedInIcon />
                </Badge>
              </IconButton>
              <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Typography variant="h6" noWrap className={classes.accountMenuTitle}>{user.firstname}</Typography>
              </Button>
              <Menu
                PopoverClasses={{
                  paper: classes.accountMenuPaper
                }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={accountMenuOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>Afmelden</MenuItem>
              </Menu>
            </div>
        </Toolbar>
      </AppBar>

      <Drawer
        PaperProps={{ elevation: 16 }}
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton className={classes.menuButtonOpen} onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <MenuIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h5" noWrap className={classes.title}><img className={classes.appBarLogo} src={process.env.PUBLIC_URL + '/logo.png'}/></Typography>
        </div>
        <Divider />
        <List>
          {user.level >= 1 &&
            <ListItem onClick={() => handleDrawerClose('/')} button>
              <ListItemIcon><DashboardIcon /></ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItem>
          }
          {user.level >= 2 &&
            <ListItem onClick={() => handleDrawerClose('/planning/' + moment().format("YYYY/MM/DD"))} button>
              <ListItemIcon><ScheduleIcon /></ListItemIcon>
              <ListItemText primary={"Planning"} />
            </ListItem>
          }
          {user.level >= 3 &&
            <ListItem onClick={() => handleDrawerClose('/analytics/' + moment().format("YYYY/MM/DD"))} button>
              <ListItemIcon><BarChartIcon /></ListItemIcon>
              <ListItemText primary={"Analytics"} />
            </ListItem>
          }
        </List>
        <Divider />
        <List>
          {user.level >= 2 &&
            <ListItem onClick={() => handleDrawerClose('/monitoring/putaway')} button>
              <ListItemIcon><VisibilityIcon /></ListItemIcon>
              <ListItemText primary={"Ingeslagen voorraad"} />
            </ListItem>
          }
          {user.level >= 2 &&
            <ListItem onClick={() => handleDrawerClose('/monitoring/collected')} button>
              <ListItemIcon><ShoppingBasketIcon /></ListItemIcon>
              <ListItemText primary={"Verzamelde bestellingen"} />
            </ListItem>
          }
        </List>
        {user.level >= 2 &&
          <Divider />
        }
        <List>
          {user.level >= 1 &&
            <ListItem onClick={() => handleDrawerClose('/tools/replenisher')} button>
              <ListItemIcon><SyncAltIcon /></ListItemIcon>
              <ListItemText primary={"Verplaatsen"} />
            </ListItem>
          }
          {/* {user.level >= 1 &&
            <ListItem onClick={() => handleDrawerClose('/tools/putaway')} button>
              <ListItemIcon><ShuffleIcon /></ListItemIcon>
              <ListItemText primary={"Wegzetten"} />
            </ListItem>
          } */}
        </List>
        <Divider />
        <List>
          <ListItem onClick={() => handleDrawerClose('/monitor/outbound?key=5t4cDx68GX95Ag3O7npP8MHwQ3SX0moxjI175e4fuNBF63tjn2Q4zUO5K9Im90USa9QsSYmQ3KP5OYtP5LqqRQzbtVis7FH2')} button>
            <ListItemIcon><PersonalVideoIcon /></ListItemIcon>
            <ListItemText primary={"Magazijn monitor"} />
          </ListItem>
        </List>
      </Drawer>

      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  )

};

export default AppTemplate;
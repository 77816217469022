import axios from "axios";

const ShopWeDoAPI = axios.create({
  baseURL: "https://wts.shopwedo.net/api/",
});

const ShopWeDoAuth = axios.create({
  baseURL: "https://wts.shopwedo.net/api/",
});

ShopWeDoAPI.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  })

ShopWeDoAPI.interceptors.response.use(response => response, error => {
  const status = error.response ? error.response.status : null
  if (status === 401 && error.config && !error.config.__isRetryRequest) {
    return ShopWeDoAuth.get('auth/refresh').then(refreshResponse => {
      let refreshedToken = refreshResponse.data.accessToken
      localStorage.setItem('accessToken', refreshedToken)
      error.config.headers['Authorization'] = 'Bearer ' + refreshedToken;
      error.config.__isRetryRequest = true
      return ShopWeDoAPI.request(error.config);
    }).catch(err => {
      localStorage.removeItem('accessToken')
      window.location = '/login';
    });
  }
  return Promise.reject(error);
});

export default ShopWeDoAPI
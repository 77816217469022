import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import nlBeLocale from "date-fns/locale/nl-be";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  field: {
    margin: theme.spacing(2)
  },
}));

const DateTimePicker = ({ value, onDateChange, dateLabel, timeLabel }) => {
  const classes = useStyles();

  const [date, setDate] = useState(value)

  useEffect(() => {
    if (typeof onDateChange === 'function') onDateChange(date)
  }, [date])

  const changeDate = (newDate) => setDate(newDate)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlBeLocale}>
      <KeyboardDatePicker
          className={classes.field}
          label={dateLabel}
          format="dd/MM/yyyy"
          value={date}
          inputVariant="outlined"
          onChange={changeDate}
        />
        <KeyboardTimePicker
          className={classes.field}
          ampm={false}
          label={timeLabel}
          value={date}
          inputVariant="outlined"
          onChange={changeDate}
        />
    </MuiPickersUtilsProvider>
  )
}

export default DateTimePicker

import React, { useState, useEffect, useContext } from 'react'
import { appContext } from '../contexts/AppContext';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ShopWeDoAPI from '../contexts/ShopWeDoAPI'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  tableRowWarning: {
    backgroundColor: theme.palette.secondary.light
  },
  tableRowDanger: {
    backgroundColor: theme.palette.error.light
  },
}));

function CollectedMonitoring() {
  const classes = useStyles();
  const { changeTitle } = useContext(appContext);

  const [orders, setOrders] = useState([])

  useEffect(() => {
    changeTitle('Verzamelde bestellingen')
    ShopWeDoAPI.get('orders/collected').then(collectedOrdersResponse => {
      let collectedOrders = collectedOrdersResponse.data
      collectedOrders.map(order => {
        let diff = (order.histories.length) ? moment().diff(moment(order.histories[0].createdAt), 'days') : 0
        order.diff = diff
      })
      setOrders(collectedOrders)
    })
  }, [])

  const CollectedOrderRow = ({children, key, order}) => {
    if (order.diff == 1) {
      return <TableRow className={classes.tableRowWarning} key={key}>{children}</TableRow>
    } else if (order.diff > 1) {
      return <TableRow className={classes.tableRowDanger} key={key}>{children}</TableRow>
    }
    return <TableRow key={key}>{children}</TableRow>
  }

  return(
    <div className={classes.root}>
      {orders && orders.length > 0 &&
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Shop</TableCell>
                <TableCell>Adres</TableCell>
                <TableCell>Verzameld</TableCell>
                <TableCell>Q&amp;A Opgelost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order, index) => (
                <CollectedOrderRow key={index} order={order}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>{order.shop.name}</TableCell>
                  <TableCell>{order.shippingAddress.zip} {order.shippingAddress.city}, {order.shippingAddress.country}</TableCell>
                  <TableCell>{order.histories.length > 0 &&
                    <Moment format="DD/MM/YYYY HH:mm:ss">{order.histories[0].createdAt}</Moment>
                  }</TableCell>
                  <TableCell>{order.activities.length > 0 && 
                    <Moment format="DD/MM/YYYY HH:mm:ss">{order.activities[0].createdAt}</Moment>
                  }</TableCell>
                </CollectedOrderRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </div>
  )
}

export default CollectedMonitoring;
import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import ProtectedRoute from './components/ProtectedRoute'
import KeyRoute from './components/KeyRoute'

import Login from './pages/Login';
import Homepage from './pages/Homepage';
import Planning from './pages/Planning';
import Analytics from './pages/Analytics';
import PutawayMonitoring from './pages/PutawayMonitoring';
import CollectedMonitoring from './pages/CollectedMonitoring';
import Info from './pages/Info';
import Replenisher from './pages/Replenisher';
import WarehouseMonitor from './pages/WarehouseMonitor';

const useStyles = makeStyles((theme) => ({
  app: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: '#e1e3eb',
    overflow: 'hidden'
  },
  appInner: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    overflow: 'scroll'
  }
}));

function App() {
  const classes = useStyles();

  return (
    <div className={classes.app}><div className={classes.appInner}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <ProtectedRoute exact path="/" level={1} component={Homepage} />
          <ProtectedRoute exact path="/planning/:year/:month/:day" level={2} component={Planning} />
          <ProtectedRoute exact path="/analytics/:year/:month/:day" level={3} component={Analytics} />
          <ProtectedRoute exact path="/monitoring/putaway" level={1} component={PutawayMonitoring} />
          <ProtectedRoute exact path="/monitoring/collected" level={2} component={CollectedMonitoring} />
          <ProtectedRoute exact path="/tools/info" level={1} component={Info} />
          <ProtectedRoute exact path="/tools/replenisher" level={1} component={Replenisher} />
          <KeyRoute exact path="/monitor/outbound" component={WarehouseMonitor} />
          <Route path="*" component={() => "404 NOT FOUND"} />
        </Switch>
      </Router>
    </div></div>
  );
}

export default App;

import React, { useContext, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { authContext } from '../contexts/AuthContext';

import AppTemplate from './AppTemplate';

const ProtectedRoute = ({ component: Component, level, ...rest }) => {
  const { accessToken, hasAccess, restoreUser } = useContext(authContext);

  useEffect(() => restoreUser(accessToken), [])

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        hasAccess(accessToken, level) ? <AppTemplate {...routeProps}><Component {...routeProps} /></AppTemplate> : (accessToken) ? <Redirect to="/" /> : <Redirect to="/login" />
      )}
    />
  )
}

export default ProtectedRoute

import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { appContext } from '../contexts/AppContext';
import { authContext } from '../contexts/AuthContext';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/nl-be';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddShiftDialog from '../dialogs/AddShiftDialog';
import AddAssignmentDialog from '../dialogs/AddAssignmentDialog';
import DateSwitcher from '../components/DateSwitcher';

import ShopWeDoAPI from '../contexts/ShopWeDoAPI'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  planningTitle: {
    textTransform: 'uppercase'
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: '100%',
  },
  taskCell: {
    width: '200px'
  },
  startCell: {
    width: '300px'
  },
  endCell: {
    width: '300px'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  shiftToolbar: {
    display: 'flex',
  },
  shiftName: {
    flexGrow: 1,
    textTransform: 'uppercase'
  },
  shiftInfo: {
    marginLeft: theme.spacing(2)
  },
  cardRoot: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  contentRoot: {
    paddingLeft: 0,
    paddingRight: 0
  },
  taskTitle: {
    marginTop: theme.spacing(1)
  },
  taskEntry: {
    marginTop: theme.spacing(1),
    borderTop: '1px solid rgb(0 0 0 / 12%)'
  }
}));

function Planning({ match }) {
  let history = useHistory();
  const classes = useStyles();
  const { changeTitle } = useContext(appContext);
  const { user } = useContext(authContext);

  const [ shifts, setShifts ] = useState([])
  const [ date, setDate ] = useState(match.params.year+'/'+match.params.month+'/'+match.params.day)

  const [addShiftOpen, setAddShiftOpen] = useState(false)

  const [addAssignmentOpen, setAddAssignmentOpen] = useState(false)
  const [assignmentShift, setAssignmentShift] = useState(null)

  useEffect(() => {
    changeTitle('Planning')
  }, [])

  useEffect(() => {
    ShopWeDoAPI.get('shifts/' + moment(date).format("YYYY/MM/DD")).then(shiftsResponse => {
      setShifts(shiftsResponse.data)
    })
  }, [date])

  const onDateChange = (d) => {
    setDate(d)
    history.push('/planning/' + moment(d).format("YYYY/MM/DD"))
  }

  const onShiftPosted = () => {
    setAddShiftOpen(false)
    ShopWeDoAPI.get('shifts/' + moment(date).format("YYYY/MM/DD")).then(shiftsResponse => {
      setShifts(shiftsResponse.data)
    })
  }

  const openAssignmentDialog = (shift) => {
    setAssignmentShift(shift)
  }

  const closeAssignmentDialog = () => {
    setAssignmentShift(null)
  }

  useEffect(() => {
    if (assignmentShift) {
      setAddAssignmentOpen(true)
    } else {
      setAddAssignmentOpen(false)
    }
  }, [assignmentShift])

  const onAssignmentPosted = () => {
    setAddAssignmentOpen(false)
    ShopWeDoAPI.get('shifts/' + moment(date).format("YYYY/MM/DD")).then(shiftsResponse => {
      setShifts(shiftsResponse.data)
    })
  }

  return (
    <div className={classes.root}>
      <DateSwitcher onDateChange={(d) => onDateChange(d)} urlDate={match.params.year+'/'+match.params.month+'/'+match.params.day}>
        <Typography display='inline' align='center' variant="h6" noWrap className={classes.planningTitle}><Moment format="dd, D MMM YYYY">{date}</Moment></Typography>
      </DateSwitcher>
      {shifts.map((shift, index) => (
        <Paper className={classes.cardRoot}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={(user.level >= 3) ? 10 : 12}><Typography className={classes.shiftName} variant="body1" noWrap>{shift.user.firstname + ' ' + shift.user.lastname}</Typography></Grid>
            {user.level >= 3 && 
              <Grid item xs={2} align="right"><IconButton onClick={() => openAssignmentDialog(shift)} size="small" color="primary" component="span"><AddIcon /></IconButton></Grid>
            }
            <Grid item xs={3}><Typography variant="h6" align="center" display="block" noWrap><Moment format="HH:mm">{shift.start}</Moment></Typography><Typography variant="caption" color="textSecondary" align="center" display="block" noWrap>BEGIN</Typography></Grid>
            <Grid item xs={3}><Typography variant="h6" align="center" display="block" noWrap><Moment format="HH:mm">{shift.end}</Moment></Typography><Typography variant="caption" color="textSecondary" align="center" display="block" noWrap>EINDE</Typography></Grid>
            <Grid item xs={3}><Typography variant="h6" align="center" display="block" noWrap>{shift.duration}</Typography><Typography variant="caption" align="center" color="textSecondary" display="block" noWrap>UREN</Typography></Grid>
            <Grid item xs={3}><Typography variant="h6" align="center" display="block" noWrap>{shift.break}</Typography><Typography variant="caption" align="center" color="textSecondary" display="block" noWrap>PAUZE</Typography></Grid>
          </Grid>
          {shift.assignments && shift.assignments.length > 0 &&
            <Grid container spacing={2} className={classes.taskTitle}><Grid item xs={12}><Typography variant="caption" align="left" display="block" noWrap>TAKEN</Typography></Grid></Grid>
          }
          {shift.assignments && shift.assignments.length > 0 &&
            shift.assignments.map((assignment, index) => (
              <Grid container spacing={2} className={classes.taskEntry}>
                <Grid item xs={4}>{assignment.assignment.description}</Grid>
                <Grid item xs={4}><Moment format="HH:mm">{assignment.start}</Moment></Grid>
                <Grid item xs={4}><Moment format="HH:mm">{assignment.end}</Moment></Grid>
              </Grid>
            ))
          }
        </Paper>
      ))}
      {user.level >= 3 &&
        <Fab onClick={() => setAddShiftOpen(true)} className={classes.fab} color="secondary"><AddIcon /></Fab>
      }
      {user.level >= 3 &&
        <AddShiftDialog onShiftPosted={onShiftPosted} date={date} onClose={() => setAddShiftOpen(false)} open={addShiftOpen}/>
      }
      {user.level >= 3 &&
        <AddAssignmentDialog onAssignmentPosted={onAssignmentPosted} shift={assignmentShift} onClose={closeAssignmentDialog} open={addAssignmentOpen}/>
      }
    </div>
  )

  // return(
  //   <div className={classes.root}>
  //     <DateSwitcher onDateChange={(d) => onDateChange(d)} urlDate={match.params.year+'/'+match.params.month+'/'+match.params.day}>
  //       <Typography display='inline' align='center' variant="h6" noWrap className={classes.planningTitle}><Moment format="dddd, D MMMM YYYY">{date}</Moment></Typography>
  //     </DateSwitcher>
  //     {shifts.map((shift, index) => (
  //       <TableContainer className={classes.tableContainer} key={index} component={Paper}>
  //       <Toolbar className={classes.shiftToolbar}>
  //         <Typography className={classes.shiftName} variant="h5" noWrap>{shift.user.firstname} {shift.user.lastname} {user.level >= 3 && <IconButton onClick={() => openAssignmentDialog(shift)} color="primary" component="span"><AddIcon /></IconButton>}</Typography>
  //         <Typography className={classes.shiftInfo} variant="overline" noWrap>Begin: </Typography>
  //         <Typography variant="h6" noWrap><Moment format="HH:mm">{shift.start}</Moment></Typography>
  //         <Typography className={classes.shiftInfo} variant="overline" noWrap>Einde: </Typography>
  //         <Typography variant="h6" noWrap><Moment format="HH:mm">{shift.end}</Moment></Typography>
  //         <Typography className={classes.shiftInfo} variant="overline" noWrap>Pauze: </Typography>
  //         <Typography variant="h6" noWrap>{shift.break}min</Typography>
  //         <Typography className={classes.shiftInfo} variant="overline" noWrap>Uren: </Typography>
  //         <Typography variant="h6" noWrap>{shift.duration}u</Typography>
  //       </Toolbar>
  //       {shift.assignments && shift.assignments.length > 0 &&
  //         <Table className={classes.table} aria-label="simple table">
  //           <TableHead>
  //             <TableRow>
  //               <TableCell className={classes.taskCell}>Taak</TableCell>
  //               <TableCell className={classes.startCell}>Begin</TableCell>
  //               <TableCell className={classes.endCell}>Einde</TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //           {shift.assignments.map((assignment, index) => (
  //             <TableRow key={index}>
  //                 <TableCell className={classes.taskCell}>{assignment.assignment.description}</TableCell>
  //                 <TableCell className={classes.startCell}><Moment format="HH:mm">{assignment.start}</Moment></TableCell>
  //                 <TableCell className={classes.endCell}><Moment format="HH:mm">{assignment.end}</Moment></TableCell>
  //               </TableRow>
  //             ))}
  //           </TableBody>
  //         </Table>
  //       }
  //     </TableContainer>
  //     ))}
  //     {user.level >= 3 &&
  //       <Fab onClick={() => setAddShiftOpen(true)} className={classes.fab} color="secondary"><AddIcon /></Fab>
  //     }
  //     {user.level >= 3 &&
  //       <AddShiftDialog onShiftPosted={onShiftPosted} date={date} onClose={() => setAddShiftOpen(false)} open={addShiftOpen}/>
  //     }
  //     {user.level >= 3 &&
  //       <AddAssignmentDialog onAssignmentPosted={onAssignmentPosted} shift={assignmentShift} onClose={closeAssignmentDialog} open={addAssignmentOpen}/>
  //     }
  //   </div>
  // )
}

export default withRouter(Planning);
import React, { useState, useEffect, useContext } from 'react'
import _ from "lodash"
import { withRouter } from "react-router"
import { useHistory } from "react-router-dom"
import { appContext } from '../contexts/AppContext'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/nl-be';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import DateSwitcher from '../components/DateSwitcher';
import ShopWeDoAPI from '../contexts/ShopWeDoAPI'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  tableContainer: {
    marginBottom: theme.spacing(2)
  },
  tableCellPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff'
  },
  tableCellWarning: {
    backgroundColor: theme.palette.secondary.main
  },
  tableCellSuccess: {
    backgroundColor: theme.palette.success.main
  },
  analyticsTitle: {
    textTransform: 'uppercase'
  },
  shipChip: {
    backgroundColor: theme.palette.success.main
  },
  pickChip: {
    backgroundColor: theme.palette.secondary.main
  }
}));

function Analytics({ match }) {
  let history = useHistory();
  const classes = useStyles();
  const { changeTitle } = useContext(appContext);
  const [ date, setDate ] = useState(match.params.year+'/'+match.params.month+'/'+match.params.day)

  const [ logs, setLogs ] = useState([])
  const [ pickingLogs, setPickingLogs ] = useState([])
  const [ shippingLogs, setShippingLogs ] = useState([])

  const [ totalCombined, setTotalCombined ] = useState(null)
  const [ totalPicking, setTotalPicking ] = useState(null)
  const [ totalShipping, setTotalShipping ] = useState(null)

  useEffect(() => {
    changeTitle('Analytics')
  }, [])

  useEffect(() => {
    let groupedLogs = _.groupBy(logs, 'userId')
    let arrayLogs = []
    Object.keys(groupedLogs).forEach(key => {
      arrayLogs.push({
        firstname: groupedLogs[key][0].user.firstname,
        orderlines: groupedLogs[key].length,
        orders: Object.entries(_.groupBy(groupedLogs[key], 'orderId')).length
      })
    })
    arrayLogs.sort((a, b) => b.orderlines - a.orderlines)
    let totalOrders = arrayLogs.reduce((t, i) => t + i.orders, 0)
    let totalOrderlines = arrayLogs.reduce((t, i) => t + i.orderlines, 0)
    setTotalCombined({
      logs: arrayLogs,
      totalOrders: totalOrders,
      totalOrderlines: totalOrderlines
    })
  }, [logs])

  useEffect(() => {
    let groupedLogs = _.groupBy(pickingLogs, 'userId')
    let arrayLogs = []
    Object.keys(groupedLogs).forEach(key => {
      arrayLogs.push({
        firstname: groupedLogs[key][0].user.firstname,
        orderlines: groupedLogs[key].length,
        orders: Object.entries(_.groupBy(groupedLogs[key], 'orderId')).length
      })
    })
    arrayLogs.sort((a, b) => b.orderlines - a.orderlines)
    let totalOrders = arrayLogs.reduce((t, i) => t + i.orders, 0)
    let totalOrderlines = arrayLogs.reduce((t, i) => t + i.orderlines, 0)
    setTotalPicking({
      logs: arrayLogs,
      totalOrders: totalOrders,
      totalOrderlines: totalOrderlines
    })
  }, [pickingLogs])

  useEffect(() => {
    let groupedLogs = _.groupBy(shippingLogs, 'userId')
    let arrayLogs = []
    Object.keys(groupedLogs).forEach(key => {
      arrayLogs.push({
        firstname: groupedLogs[key][0].user.firstname,
        orderlines: groupedLogs[key].length,
        orders: Object.entries(_.groupBy(groupedLogs[key], 'orderId')).length
      })
    })
    arrayLogs.sort((a, b) => b.orderlines - a.orderlines)
    let totalOrders = arrayLogs.reduce((t, i) => t + i.orders, 0)
    let totalOrderlines = arrayLogs.reduce((t, i) => t + i.orderlines, 0)
    setTotalShipping({
      logs: arrayLogs,
      totalOrders: totalOrders,
      totalOrderlines: totalOrderlines
    })
  }, [shippingLogs])

  useEffect(() => {
    setLogs([])
    ShopWeDoAPI.get('analytics/' + moment(date).format("YYYY/MM/DD")).then(analyticsResponse => {
      setPickingLogs(analyticsResponse.data.pickingLogs)
      setShippingLogs(analyticsResponse.data.shippingLogs)
      let combinedLogs = analyticsResponse.data.shippingLogs.concat(analyticsResponse.data.pickingLogs)
      combinedLogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setLogs(combinedLogs)
    })
  }, [date])

  const onDateChange = (d) => {
    setDate(d)
    history.push('/analytics/' + moment(d).format("YYYY/MM/DD"))
  }

  const CollectedOrderRow = ({children, key, log}) => {
    return <TableRow key={key}>{children}</TableRow>
  }

  return(
    <div className={classes.root}>
      <DateSwitcher onDateChange={(d) => onDateChange(d)} urlDate={match.params.year+'/'+match.params.month+'/'+match.params.day}>
        <Typography display='inline' align='center' variant="h6" noWrap className={classes.analyticsTitle}><Moment format="dd, D MMM YYYY">{date}</Moment></Typography>
      </DateSwitcher>
      {logs && logs.length > 0 &&
        <Grid container spacing={2}>
          <Grid item xs={8}>
          <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Tijdstip</TableCell>
                <TableCell>Magazijnier</TableCell>
                <TableCell>Order</TableCell>
                <TableCell>Orderlijn</TableCell>
                <TableCell>Aantal</TableCell>
                <TableCell>Stock</TableCell>
                <TableCell>Shop</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <CollectedOrderRow key={index} log={log}>
                  <TableCell>{log.type == 'SHIPPING' ? <Chip className={classes.shipChip} label={log.type}/> : <Chip className={classes.pickChip} label={log.type}/>}</TableCell>
                  <TableCell><Moment format="DD/MM/YYYY HH:mm:ss">{log.createdAt}</Moment></TableCell>
                  <TableCell>{log.user.firstname}</TableCell>
                  <TableCell>{log.orderId}</TableCell>
                  <TableCell>{log.orderlineId}</TableCell>
                  <TableCell>{log.amount}</TableCell>
                  <TableCell>{(log.stock) ? log.stock.id : '0'} - {(log.stock) ? log.stock.name : 'geen stock product'}</TableCell>
                  <TableCell>{(log.stock) ? log.stock.shop.name : ''}</TableCell>
                </CollectedOrderRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
          </Grid>
          <Grid item xs={4}>
            {totalCombined && 
              <TableContainer className={classes.tableContainer} component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellPrimary} align="center" colSpan={2}>{totalShipping.totalOrders} ({totalCombined.totalOrderlines})</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {totalCombined.logs.map((log, index) => (
                    <TableRow key={index}>
                      <TableCell>{log.firstname}</TableCell>
                      <TableCell>{log.orders} ({log.orderlines})</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            }

            {totalPicking && 
              <TableContainer className={classes.tableContainer} component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellWarning} align="center" colSpan={2}>{totalPicking.totalOrders} ({totalPicking.totalOrderlines})</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {totalPicking.logs.map((log, index) => (
                    <TableRow key={index}>
                      <TableCell>{log.firstname}</TableCell>
                      <TableCell>{log.orders} ({log.orderlines})</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            }

            {totalShipping && 
              <TableContainer className={classes.tableContainer} component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellSuccess} align="center" colSpan={2}>{totalShipping.totalOrders} ({totalShipping.totalOrderlines})</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {totalShipping.logs.map((log, index) => (
                    <TableRow key={index}>
                      <TableCell>{log.firstname}</TableCell>
                      <TableCell>{log.orders} ({log.orderlines})</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            }
          </Grid>
        </Grid>
      }
    </div>
  )
}

export default withRouter(Analytics);
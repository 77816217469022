import React, { createContext, useState, useEffect } from 'react';

export const appContext = createContext({});

const AppProvider = ({ children }) => {
  const [title, setTitle] = useState('ShopWeDo | Dashboard');
  const [numbersOfTasks, setNumberOfTasks] = useState(0)

  useEffect(() => {
    document.title = 'ShopWeDo | ' + title;
  }, [title])

  const changeTitle = (title) => {
    setTitle(title)
  }
  
  return (
    <appContext.Provider value={{ numbersOfTasks, setNumberOfTasks, title, changeTitle }}>
      {children}
    </appContext.Provider>
  );
};

export default AppProvider;
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateTimePicker from '../components/DateTimePicker'
import FormDialog from '../components/FormDialog'
import moment from 'moment';

import ShopWeDoAPI from '../contexts/ShopWeDoAPI'

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  withMargin: {
    margin: theme.spacing(2),
  }
}));

export default function AddAssignmentDialog({ onAssignmentPosted, shift, onClose, open }) {
  const classes = useStyles()

  const [assignments, setAssignments] = useState([])
  useEffect(() => {
    ShopWeDoAPI.get('assignments').then(assignmentsResponse => {
        setAssignments(assignmentsResponse.data)
    })
  }, [])

  useEffect(() => {
    if (shift) {
      setStartDate(moment(shift.start))
      setEndDate(moment(shift.end))
    }
  }, [shift])

  const [assignment, setAssignment] = useState(null)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const handleAssignmentChange = (_, v) => setAssignment(v)

  const handleStartDateChange = (d) => setStartDate(d)
  const handleEndDateChange = (d) => setEndDate(d)

  const onSubmit = () => {
    ShopWeDoAPI.post('shifts/' + shift.id + '/shift-assignments', {
        assignmentId: assignment.id,
        start: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(endDate).format('YYYY-MM-DD HH:mm:ss')
    }).then(_ => {
        if (typeof onAssignmentPosted === 'function') onAssignmentPosted()
    })
  }

  return (
    <div>
      <FormDialog onSubmit={onSubmit} onClose={onClose} open={open} title="Taak toevoegen">
         <Autocomplete 
            className={classes.withMargin}
            options={assignments} 
            getOptionLabel={(assignment) => assignment.description} 
            style={{ display: 'block' }} 
            onChange={handleAssignmentChange}
            renderInput={(params) => <TextField {...params} label="Taak" variant="outlined" />}
            />
            <DateTimePicker dateLabel='Start datum' timeLabel='Start uur' value={startDate} onDateChange={handleStartDateChange} />
            <DateTimePicker dateLabel='Einde datum' timeLabel='Einde uur' value={endDate} onDateChange={handleEndDateChange} />
      </FormDialog>
    </div>
  );
}